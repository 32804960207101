import axiosInstance from './utils';

export default {
  name: 'force_open',

  forceOpen(doorId) {
    return axiosInstance.post(`/${this.name}?id=${encodeURIComponent(doorId)}`)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
