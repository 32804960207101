<template>
  <v-data-table
    light dense
    :headers="headers"
    :items="badgeIndexItems"
    :items-per-page="itemsPerPage"
    :search="search"
    sort-by="badgeIndex"
    class="elevation-3 grey lighten-5"
  >
    <template v-slot:top>
      <v-toolbar flat class="grey lighten-5">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          clear-icon="mdi-eraser"
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" tile
              v-bind="attrs"
              v-on="on"
            > {{ $t("badge.c") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="formBadgeIndexTable" v-model="valid" dense>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" >
                      <v-text-field
                        v-model="badgeMacAddress"
                        :label="$t('door.badgeIndexListForDoor.badge')"
                        :hint="scanQrCodesHint"
                        persistent-hint
                        :rules="rulesBadgeMacAddress"
                        clearable
                        clear-icon="mdi-eraser"
                      ></v-text-field>
                      <v-card class="ma-2" v-if="macAddressAreadyExistsWarningMessage">
                        <v-card-text
                          v-html="macAddressAreadyExistsWarningMessage"
                          class="warning">
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" dense class="d-flex justify-center">
                      <v-btn v-if="!scanQrCode"
                        dark fab
                        class="mx-4"
                        color="primary"
                        @click="scanQrCode = true"
                        >
                        <v-icon large left class="ma-2"> mdi-qrcode-scan</v-icon>
                      </v-btn>
                      <div v-if="scanQrCode">
                        <qrcode-stream @decode="onDecode"></qrcode-stream>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" >
                      <v-text-field
                        v-model="badgeIndex"
                        :label="$t('door.badgeIndexListForDoor.index')"
                        :rules="rulesBadgeIndex"
                        clearable
                        clear-icon="mdi-eraser"
                      ></v-text-field>
                      <v-card class="ma-2" v-if="badgeIndexAreadyExistsWarningMessage">
                        <v-card-text
                          v-html="badgeIndexAreadyExistsWarningMessage"
                          class="warning">
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" >
                      <v-text-field
                        v-model="user"
                        :label="$t('door.badgeIndexListForDoor.user')"
                        readonly
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">{{ $t("appButton.cancel") }}</v-btn>
              <v-btn color="blue darken-1" text
                :disabled="!valid"
                @click="save"
              >{{ $t("appButton.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-subtitle class="pa-4">
              {{ confirmDeleteItemMessage }}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{ $t("appButton.cancel") }}</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{ $t("appButton.delete") }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>

    <template v-slot:no-data>
      {{ $t("errMsg.noDataRegistered") }}
    </template>
  </v-data-table>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import rules from '../model/validationRules';
import i18n from '../i18n';

export default {
  name: 'BadgeIndexListForDoorTable',
  components: {
    QrcodeStream,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: i18n.t('door.badgeIndexListForDoor.badge'), value: 'badgeMacAddress' },
      { text: i18n.t('door.badgeIndexListForDoor.index'), value: 'badgeIndex' },
      { text: i18n.t('door.badgeIndexListForDoor.user'), value: 'user' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    badgeIndexItems: [],
    defaultItem: { badgeMacAddress: '', badgeIndex: null, user: '' },
    editedIndex: -1,
    editedItem: { badgeMacAddress: '', badgeIndex: null, user: '' },
    itemsPerPage: -1,
    macAddressAreadyExistsWarningMessage: '',
    badgeIndexAreadyExistsWarningMessage: '',
    scanQrCode: false,
    scanQrCodesHint: i18n.tc('badge.scanQrCode', 1),
    search: '',
    valid: true,
  }),

  props: {
    badgeIndexItemsProps: {
      type: Object,
      required: true,
    },
    refBadgeUserListProps: {
      type: Array,
      required: true,
    },
  },

  computed: {
    badgeIndex: {
      get() {
        return this.editedItem.badgeIndex;
      },
      set(badgeIndex) {
        if (badgeIndex) {
          // Check unicity
          this.badgeIndexAreadyExistsWarningMessage = '';
          this.badgeIndexItems.forEach((item, index) => {
            if (item.badgeIndex === badgeIndex.padStart(3, '0') && index !== this.editedIndex) {
              this.badgeIndexAreadyExistsWarningMessage = this.$t(
                'door.badgeIndexListForDoor.badgeIndexAreadyExistsWarningMessage',
              );
            }
          });
          this.editedItem.badgeIndex = badgeIndex;
        }
      },
    },

    badgeIndexItemsAsObject() {
      const result = {};
      this.badgeIndexItems.forEach((item) => {
        result[item.badgeMacAddress] = item.badgeIndex.padStart(3, '0');
      });
      return result;
    },

    badgeMacAddress: {
      get() {
        return this.editedItem.badgeMacAddress;
      },
      set(macAddress) {
        /*  TODO : add some more validity check (badge exists,...) */
        if (macAddress) {
          const mac = macAddress.toLowerCase();
          // Check unicity
          this.macAddressAreadyExistsWarningMessage = '';
          this.badgeIndexItems.forEach((item, index) => {
            if (item.badgeMacAddress === mac && index !== this.editedIndex) {
              this.macAddressAreadyExistsWarningMessage = this.$t(
                'door.badgeIndexListForDoor.macAddressAreadyExistsWarningMessage',
              );
            }
          });
          this.editedItem.badgeMacAddress = mac;
          this.editedItem.user = this.getUserLabelForBadge(mac);
        }
      },
    },

    confirmDeleteItemMessage() {
      let badgeMacAddress = '...';
      if (this.editedIndex < 0) return 'Confirm delete ?'; // Should never occur...
      if (this.badgeIndexItems[this.editedIndex]
          && this.badgeIndexItems[this.editedIndex].badgeMacAddress) {
        badgeMacAddress = this.badgeIndexItems[this.editedIndex].badgeMacAddress;
      }
      return i18n.t('door.badgeIndexListForDoor.confirmDeleteBadgeIndex_fmt', { badgeMacAddress });
    },

    formTitle() {
      return this.editedIndex === -1 ? i18n.t('door.badgeIndexListForDoor.newBadgeIndex')
        : i18n.t('door.badgeIndexListForDoor.editBadgeIndex');
    },

    rulesBadgeMacAddress() {
      return [rules.required, rules.isMacAddress, this.macAddressAreadyExistsWarningMessage === ''];
    },
    rulesBadgeIndex() {
      return [rules.required, rules.number, this.badgeIndexAreadyExistsWarningMessage === ''];
    },

    user: {
      get() {
        return this.editedItem.user;
      },
      set(userLabel) {
        this.editedItem.user = userLabel;
      },
    },

  },

  watch: {
    dialog(val) {
      return val || this.close();
    },
    dialogDelete(val) {
      return val || this.closeDelete();
    },

    badgeIndexItemsProps(newVal) {
      this.initialize();
      const result = [];
      Object.entries(newVal).forEach(([key, value]) => result.push({
        badgeMacAddress: key,
        badgeIndex: value,
        user: this.getUserLabelForBadge(key),
      }));
      this.badgeIndexItems = result;
      this.itemsPerPage = this.badgeIndexItems.length + 1;
    },

    badgeIndexItems() {
      this.$nextTick(() => {
        this.itemsPerPage = -1;
      });
    },

    refBadgeUserListProps() {
      this.badgeIndexItems.forEach((item) => {
        /* eslint-disable no-param-reassign */
        item.user = this.getUserLabelForBadge(item.badgeMacAddress);
        /* eslint-enable no-param-reassign */
      });
    },

  },

  created() {
    this.initialize();
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        if (this.$refs?.formBadgeIndexTable) {
          this.$refs.formBadgeIndexTable.reset();
          this.$refs.formBadgeIndexTable.resetValidation();
        }
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        if (this.$refs?.formBadgeIndexTable) {
          this.$refs.formBadgeIndexTable.reset();
          this.$refs.formBadgeIndexTable.resetValidation();
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.badgeIndexItems.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.badgeIndexItems.splice(this.editedIndex, 1);
      this.$emit('saveBadgeIndexItems', this.badgeIndexItemsAsObject);
      this.closeDelete();
    },

    editItem(item) {
      this.editedIndex = this.badgeIndexItems.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    getUserLabelForBadge(badgeMac) {
      const found = this.refBadgeUserListProps.find(
        (bu) => bu.macAddress === badgeMac,
      );
      if (found) return `${found.firstname} ${found.lastname} (${found.companyName})`;
      return `[ ${this.$t('badgeuser.noUserForBadge')} ]`;
    },

    initialize() {
      this.badgeIndexItems = [];
      this.itemsPerPage = -1;
      this.editedIndex = -1;
      this.editedItem = { ...this.defaultItem };
      this.macAddressAreadyExistsWarningMessage = '';
      this.badgeIndexAreadyExistsWarningMessage = '';
      this.scanQrCode = false;
      this.search = '';
      if (this.$refs?.formBadgeIndexTable) {
        this.$refs.formBadgeIndexTable.reset();
        this.$refs.formBadgeIndexTable.resetValidation();
      }
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.badgeIndexItems[this.editedIndex], this.editedItem);
      } else {
        this.badgeIndexItems.push(this.editedItem);
      }
      this.$emit('saveBadgeIndexItems', this.badgeIndexItemsAsObject);
      this.close();
    },

    onDecode(evt) {
      // this.editedItem.badgeMacAddress = evt.toLowerCase();
      this.badgeMacAddress = evt.toLowerCase();
      this.scanQrCode = false;
    },

  },
};
</script>
