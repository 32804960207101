<template>
  <v-dialog
    v-model="isBadgeIndexListForDoorDialogOpen"
    persistent
    max-width="75%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="isBadgeIndexListForDoorDialogValid" dense>
            <v-row dense>
              <v-col cols="12" sm="3" md="3" dense >
                <v-subheader>{{ doorSectionLabel }}</v-subheader>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="doorItem[locationFieldName.name]"
                  :label="locationFieldName.label"
                  :rules="locationFieldName.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="doorItem[locationFieldReference.name]"
                  :label="locationFieldReference.label"
                  :rules="locationFieldReference.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="doorItem[locationFieldFloorNumber.name]"
                  :label="locationFieldFloorNumber.label"
                  :rules="locationFieldFloorNumber.rules"
                  :readonly="true"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="3" md="3" dense >
                <v-subheader>{{ stationSectionLabel }}</v-subheader>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense >
                <v-text-field
                  v-model="stationLocation[stationLocationFieldStationName.name]"
                  :label="stationLocationFieldStationName.label"
                  :rules="stationLocationFieldStationName.rules"
                  :readonly="true"
                  :loading="loadingStationLocation"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="3" md="3" dense >
                <v-subheader>{{ badgeIndexSectionLabel }}</v-subheader>
              </v-col>
              <v-col cols="12" sm="9" md="9" dense >
                <v-card class="ma-2" v-if="doorIsWithoutStationWarning">
                  <v-card-text
                  v-html="doorIsWithoutStationWarningMessage"
                  class="error">
                  </v-card-text>
                </v-card>

                <badge-index-list-for-door-table
                  :refBadgeUserListProps="refBadgeUserList"
                  :badgeIndexItemsProps="doorBadgeIndexItems"
                  @saveBadgeIndexItems="updateBadgeIndexItemsFromTable"
                >
                </badge-index-list-for-door-table>

              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text color="blue darken-1"
          @click="closeForm"
        >
          {{ $t("appButton.cancel") }}
        </v-btn>
        <v-btn
          text color="blue darken-1"
          @click="save"
          :disabled="!isBadgeIndexListForDoorDialogValid"
        >
          {{ $t("appButton.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  door,
  locationFieldCapacityForDoor,
  locationFieldName,
  locationFieldReference,
  locationFieldFloorNumber,
} from '@/model/location';
import {
  stationLocationFieldStationId,
  stationLocationFieldStationName,
} from '@/model/stationlocation';
import {
  badgeFieldMacAddress,
} from '@/model/badge';

import { mapGetters } from 'vuex';
import { defaultCrudAccess, getAllowedCrudForUser } from '../model/accessRolesModel';
import i18n from '../i18n';
import badgesUsersAPI from '../api/badgesusers.api';
import stationsAPI from '../api/stations.api';
import stationsLocationsAPI from '../api/stationslocations.api';
import BadgeIndexListForDoorTable from './BadgeIndexListForDoorTable.vue';

export default {
  name: 'BadgeIndexListForDoorDialog',
  components: {
    BadgeIndexListForDoorTable,
  },
  data: () => ({
    tablename: 'door',
    badgeIndexSectionLabel: i18n.tc('door.badgeIndexListForDoor.badgeIndexSection', 1),
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    doorBadgeIndexItems: {},
    doorIsWithoutStationWarning: false,
    doorIsWithoutStationWarningMessage: i18n.tc('door.badgeIndexListForDoor.doorIsWithoutStationWarningMessage', 1),
    doorSectionLabel: i18n.tc('door.label', 1),
    isBadgeIndexListForDoorDialogValid: false,
    loading: true,
    loadingStationLocation: true,
    refBadgeUserList: [],
    stationLocation: {},
    stationSectionLabel: i18n.tc('station.label', 1),
  }),

  props: {
    isBadgeIndexListForDoorDialogOpen: {
      type: Boolean,
      default: false,
    },
    doorItem: {
      type: Object,
    },
  },

  created() {
    this.crudAccess = getAllowedCrudForUser(door.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
  },

  watch: {
    isBadgeIndexListForDoorDialogOpen(newVal) {
      if (newVal) this.refreshData();
    },

    stationLocation(newVal) {
      if (newVal && newVal?.stationParams && newVal?.stationParams?.badgeIndexItems) {
        this.doorBadgeIndexItems = newVal.stationParams.badgeIndexItems;
      } else this.doorBadgeIndexItems = {};
    },
  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),

    title() { return i18n.tc('door.badgeIndexListForDoor.label', 1); },
    badgeFieldMacAddress() { return badgeFieldMacAddress; },
    locationFieldCapacityForDoor() { return locationFieldCapacityForDoor; },
    locationFieldName() { return locationFieldName; },
    locationFieldReference() { return locationFieldReference; },
    locationFieldFloorNumber() { return locationFieldFloorNumber; },
    stationLocationFieldStationName() { return stationLocationFieldStationName; },
  },

  methods: {
    closeForm() {
      this.$emit('update:isBadgeIndexListForDoorDialogOpen', false);
      this.$nextTick(() => {
        this.resetContext();
        this.resetForm();
      });
    },

    getrefBadgeUserList() {
      this.loading = true;
      this.$nextTick(() => {
        badgesUsersAPI.getList(false).then((response) => {
          this.refBadgeUserList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getStationLocation() {
      this.loadingStationLocation = true;
      this.doorIsWithoutStationWarning = false;
      if (!this.doorItem.id) {
        const msg = i18n.tc('errMsg.undefinedValue_fmt', 1, { data: i18n.t('door.fields.id') });
        this.$store.dispatch('displayAlert', `${msg}`);
        throw new Error(msg);
      }
      this.$nextTick(() => {
        stationsLocationsAPI.getListForLocation(this.doorItem.id, false)
          .then((response) => {
            if (response?.data?.data?.length === 1) {
              [this.stationLocation] = response.data.data;
              this.doorIsWithoutStationWarning = false;
            } else {
              const msg = i18n.tc('errMsg.unexpectedItemCount_fmt', 1, {
                read: response?.data?.data?.length,
                expected: 1,
              });
              throw new Error(msg);
            }
          }).catch((error) => {
            this.doorIsWithoutStationWarning = true;
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
              { operation: this.$t('crudLabels.read').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loadingStationLocation = false;
          });
      });
    },

    refreshData() {
      this.crudAccess = getAllowedCrudForUser(door.crudAllowedRoles, this.userRoles);
      this.setCrudLabels();
      this.resetContext();
      this.getrefBadgeUserList();
      this.getStationLocation();
    },

    resetContext() {
      this.doorIsWithoutStationWarning = false;
      this.stationLocation = {};
    },

    resetForm() {
      if (this?.$refs?.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    save() {
      try {
        const editedRecord = { ...this.stationLocation };
        const stationId = stationLocationFieldStationId.getValueFromId(editedRecord.id);
        this.sendUpdatesToApiThenClose(stationId);
      } catch (error) {
        const msg = this.$t('errMsg.abortedOperationMsg');
        console.error(`Error : ${msg}`, error);
        this.$store.dispatch('displayAlert', `${msg}: ${error}`);
      }
    },

    sendUpdatesToApiThenClose(stationId) {
      /* Get the expected station then update its stationParams.badgeIndexItems value */
      let updateDone = false;
      let station = null;
      if (!stationId) {
        throw new Error(this.$tc('errMsg.undefinedValue_fmt', 1, { data: i18n.t('station.fields.id') }));
      }
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc('station.runningMsg_Fmt', 1,
        { operation: this.$t('crudLabels.reading') }));
      this.$nextTick(() => {
        /* Read/get the station item to be updated */
        stationsAPI.getItem(stationId)
          .then((response) => {
            if (response.status === 200) {
              [station] = response.data.data;
              this.$store.dispatch('displayInfo', this.$tc('station.doneMsg_Fmt', 1,
                { operation: this.$t('crudLabels.read') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc('station.abortedMsg_Fmt', 1,
              { operation: this.$t('crudLabels.read').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            /* perform Update */
            if (station) {
              station.stationParams.badgeIndexItems = JSON.parse(
                JSON.stringify(this.doorBadgeIndexItems),
              );
              this.$store.dispatch('displayInfo', this.$tc('station.runningMsg_Fmt', 1,
                { operation: this.$t('crudLabels.updating') }));
              stationsAPI.update(stationId, station)
                .then((data) => {
                  if (data.status === 201) {
                    updateDone = true;
                    this.$store.dispatch('displayInfo', this.$tc('station.doneMsg_Fmt', 1,
                      { operation: this.$t('crudLabels.updated') }));
                  }
                })
                .catch((error) => {
                  const msg = this.$tc('station.abortedMsg_Fmt', 1,
                    { operation: this.$t('crudLabels.update').toLowerCase() });
                  const apiErrorMsg = error?.response?.data?.error_msg || '';
                  console.error(`Error : ${msg}`, error, apiErrorMsg);
                  this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
                }).then(() => {
                  this.loading = false;
                  if (updateDone) this.closeForm();
                });
            } else {
              this.loading = false;
            }
          });
      });
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },

    updateBadgeIndexItemsFromTable(items) {
      this.doorBadgeIndexItems = items;
    },

  },
};
</script>
